import React ,{useState,useEffect} from 'react';
import { NavLink } from 'react-router-dom';
import ScrollToTop from 'react-scroll-to-top';

export default function End() {

 const Site_Url = "https://gazettenotification.com/";
 const BASE_URL = Site_Url + "admin/API/";

 //website Setting API
 const [webSetting, setwebSetting] = useState([]);
 useEffect(() => {
   const webSettingData = async () => {
     const response = await fetch(`${BASE_URL}website_settingAPI.php`);
     const jsonData = await response.json();
     setwebSetting(jsonData);
   };
   webSettingData();
 }, [BASE_URL]);

  return (
    <>
      <section className="footer_sec">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="footer_tx">
                <img src={webSetting.footerlogo} alt="" title="" />
                <p>{webSetting.footer_content}</p>

                <ul>
                  <li>
                    <NavLink to={webSetting.facebook}>
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to={webSetting.twitter}>
                      <i className="fa fa-twitter" aria-hidden="true"></i>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to={webSetting.instagram}>
                      <i className="fa fa-instagram" aria-hidden="true"></i>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to={webSetting.youtube}>
                      <i className="fa fa-youtube-play" aria-hidden="true"></i>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to={`https://wa.me/${webSetting.whatsaap}?text=Hi, I would like to speak to a Father Name Change`}
                    >
                      <i className="fa fa-whatsapp" aria-hidden="true"></i>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-3">
              <div className="footer_service">
                <h4>Our Services</h4>
                <p>
                  <NavLink exact="true" target="_blank" to={webSetting.footer2}>
                    {webSetting.footer1}
                  </NavLink>
                </p>
                <p>
                  <NavLink exact="true" target="_blank" to={webSetting.footer4}>
                    {webSetting.footer3}
                  </NavLink>
                </p>
                <p>
                  <NavLink exact="true" target="_blank" to={webSetting.footer6}>
                    {webSetting.footer5}
                  </NavLink>
                </p>
                <p>
                  <NavLink exact="true" target="_blank" to={webSetting.footer8}>
                    {webSetting.footer7}
                  </NavLink>
                </p>
                <p>
                  <NavLink
                    exact="true"
                    target="_blank"
                    to={webSetting.footer10}
                  >
                    {webSetting.footer9}
                  </NavLink>
                </p>
                <p>
                  <NavLink
                    exact="true"
                    target="_blank"
                    to={webSetting.footer12}
                  >
                    {webSetting.footer11}
                  </NavLink>
                </p>
              </div>
            </div>

            <div className="col-md-2">
              <div className="footer_links">
                <h4>Quick Links</h4>
                <p>
                  <NavLink exact="true" to="/">
                    Home
                  </NavLink>
                </p>
                <p>
                  <NavLink exact="true" to="/about">
                    About Us
                  </NavLink>
                </p>
                <p>
                  <NavLink exact="true" to="/service">
                    Services
                  </NavLink>
                </p>
                <p>
                  <NavLink exact="true" to="/blog/en">
                    Blogs
                  </NavLink>
                </p>
                <p>
                  <NavLink exact="true" to="/contact">
                    Contact Us
                  </NavLink>
                </p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="contact_footer">
                <h4>Contact Us</h4>
                <p>
                  <NavLink to="/">
                    <i className="fa fa-map-marker" aria-hidden="true"></i>{" "}
                    {webSetting.address}
                  </NavLink>
                </p>

                <p>
                  <NavLink to={`tel:${webSetting.number}`}>
                    <i className="fa fa-phone-square" aria-hidden="true"></i>{" "}
                    {webSetting.number}
                  </NavLink>
                </p>

                <p>
                  <NavLink to={`emailto:${webSetting.email}`}>
                    <i className="fa fa-envelope" aria-hidden="true"></i>{" "}
                    {webSetting.email}
                  </NavLink>
                </p>

                <p>
                  <NavLink to={webSetting.baseURl}>
                    <i className="fa fa-globe" aria-hidden="true"></i>{" "}
                    {webSetting.baseURl}
                  </NavLink>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="copyright">
          <p>{webSetting.copyright}</p>
        </div>
      </section>
      <ScrollToTop smooth="true" />
    </>
  );
}
