import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Blog() {
  let { language } = useParams();

  const Site_Url = "https://gazettenotification.com/";
  const BASE_URL = Site_Url + "admin/API/";

  // Banner Api
  const [slider, setbannerdata] = useState([]);
  useEffect(() => {
    const AllBanner = async () => {
      const response = await fetch(`${BASE_URL}bannerAPI.php`);
      const jsonData = await response.json();
      setbannerdata(jsonData);
    };
    AllBanner();
  }, [BASE_URL]);

  const [Blogs, setBlogsdata] = useState([]);
  useEffect(() => {
    const AllBlogs = async () => {
      const response = await fetch(`${BASE_URL}blogAPI.php`);
      const jsonData = await response.json();
      const resultfilter = jsonData.filter((langcat) => {
        return langcat.lang === language;
      });
      setBlogsdata(resultfilter);
    };
    AllBlogs();
  }, [language, BASE_URL]);


  const [catmetaTags, setcatmetaTags] = useState([]);
  useEffect(() => {
    const catMetaTagsData = async () => {
      const response = await fetch(`${BASE_URL}alllanguages.php?langcat=${language}`);
      const jsonData = await response.json();
      console.log(jsonData);
      setcatmetaTags(jsonData);
    };
    catMetaTagsData();
  });


  return (
    <>

      <Helmet>
        <title>{catmetaTags.metatitle}</title>
        <meta name="keywords" content={catmetaTags.metalink} />
        <meta name="description" content={catmetaTags.metadescription} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      {/* Banner Start */}
      <section>
        <div className="banner">
          <img
            src={slider.blog_banner}
            alt={slider.blog_banner_alt}
            title={slider.blog_banner_title}
          />
        </div>
      </section>
      {/* Banner End */}

      <section className="blog_sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <div className="service_title blog_title">
                <h2>Blogs</h2>
              </div>
            </div>
          </div>

          <div className="row">
            {Blogs.map((value) => {
              return (
                <>
                  <div className="col-md-3 col-6">
                    <div className="blog_bx">
                      <NavLink
                        to={
                          value.lang === "en"
                            ? `/${value.slug}`
                            : `/${value.lang}/${value.slug}`
                        }
                      >
                        <img
                          src={value.sort_img}
                          alt={value.image_alt}
                          title={value.image_title}
                        />
                      </NavLink>
                      <div className="blog_tx">
                        <span>
                          <i className="fa fa-calendar" aria-hidden="true"></i>{" "}
                          {value.date}
                        </span>
                        <h5>
                          <NavLink
                            to={
                              value.lang === "en"
                                ? `/${value.slug}`
                                : `/${value.lang}/${value.slug}`
                            }
                          >
                            {value.title}
                          </NavLink>
                        </h5>
                        <p className="blog_tx_tg">{value.tagline}</p>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}
